import Axios from "axios";
import { getRegionalizedUrl, TRACKING_API } from "@mc/common/lib/url-utils";

export const getSummaryDashboard = (time_window, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_overview_stats',
        params: {
            time_window: time_window,
            end_time: null,
            start_time: null,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}