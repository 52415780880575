import React from 'react'
import PropTypes from 'prop-types'

export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'

export const SearchContext = React.createContext();

export const initiaSearchState = {
    searchTerm: null,
}

export const searchReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload
            }
        default:
            return initiaSearchState;
    }
}

const SearchContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(searchReducer, initiaSearchState);
    return (
        < SearchContext.Provider value={[state, dispatch]}>
            {children}
        </SearchContext.Provider>
    );
}
SearchContextProvider.propTypes = {
    children: PropTypes.node,
}

export default SearchContextProvider