import React from 'react'
import {
    makeStyles,
    Box,
    Grid,
} from '@material-ui/core'
import EmailAccessLocationCard from './EmailAccessLocationCard'
import DeviceActivityCard from './DeviceActivityCard'

const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
    },
    printButton: {
        height: 32,
        marginBottom: 18,
        borderRadius: 6
    },
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
    },
    cardText:
    {
        paddingLeft: 20,

    },


}));

const ReconBlockedTab = () => {
    const classes = useStyles()
    return (
        <>
            <Box className={classes.summaryGrid}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <EmailAccessLocationCard />
                    </Grid>
                    <Grid item xs={12}>
                        <DeviceActivityCard />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default ReconBlockedTab