import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Card, Box, Grid, useTheme, Typography } from '@material-ui/core'
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { getData } from '../../../api/datavis'
import { AuthenticationContext } from '../../../App/AuthenticationContext';


const useStyles = makeStyles((theme) => ({
    card: {
        padding: 20,
        borderRadius: 6,

    },
    infoText: {
        paddingTop: 2,
        fontSize: 40,
        fontWeight: 600,
        lineHeight: "48px",
    },
    change: {
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "24px",
    },
}));

const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
        <g transform={`translate(${x},${y})`} >
            <text textAnchor="end" fill="#666" >{payload.value}</text>
        </g>
    )

}
CustomizedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.string,
}

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}

const DeviceActivityCard = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [authState,] = React.useContext(AuthenticationContext)
    const [deviceUsageChartData, setDeviceUsageChartData] = React.useState([])
    const [deviceList, setDeviceList] = React.useState([])
    const [deviceVersionData, setDeviceVersionData] = React.useState([])
    const isMountedRef = useIsMountedRef()


    const initialGetData = () => {
        getData(authState).then(response => {
            if(isMountedRef.current){
                let deviceUsageHashedData = {}
                let incomingDeviceList = []
                response.data.device_usage.forEach(deviceDataPoint => {
                    let deviceName = deviceDataPoint.key === 'none' ? 'Other' : deviceDataPoint.key
                    incomingDeviceList.push(deviceName)
                    deviceDataPoint.bucket.forEach(deviceTimeBucket => {
                        let keyAsString = deviceTimeBucket.key_as_string.split('T')[0]
                        if (!deviceUsageHashedData[keyAsString]) {
                            deviceUsageHashedData[keyAsString] = { name: keyAsString }
                        }
                        deviceUsageHashedData[keyAsString][deviceName] = deviceTimeBucket.doc_count

                    })
                })
                let incomingDeviceVersionData = []
                response.data.device_version.forEach(deviceData => {
                    let deviceName = deviceData.key === 'none' ? 'Other' : deviceData.key
                    deviceData.bucket.forEach(version => {
                        incomingDeviceVersionData.push({
                            name: deviceName === 'Other' ? `${deviceName}` : `${deviceName} (${version.key === 'none' ? 'Other' : version.key})`,
                            count: version.doc_count
                        })
                    })
                })
                incomingDeviceVersionData.sort((a, b) => b.count - a.count)
                setDeviceVersionData(incomingDeviceVersionData)
                setDeviceUsageChartData(Object.values(deviceUsageHashedData) || [])
                setDeviceList(incomingDeviceList)
            }

        })
    }
    React.useEffect(initialGetData, [])


    return (
        <Card className={classes.card}>
            <Typography variant="h2" gutterBottom >
                Device Activity Report
            </Typography>
            <Typography style={{ paddingBottom: 20 }} color="textPrimary" variant="body2" >
                This dashboard displays device and version activity over the last 7 days.
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                        <Box style={{ width: "100%", height: 600, paddingBottom: 30 }}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>
                                Client Usage
                            </Typography> 
                            {(deviceUsageChartData && deviceUsageChartData.length) ?  
                            <ResponsiveContainer>
                                <BarChart
                                    data={deviceUsageChartData}
                                >
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip/>
                                    <Legend />
                                    {deviceList.map((deviceName, index) => (<Bar key={deviceName} dataKey={deviceName} stackId="a" fill={Object.values(theme.rainbowColors)[index % 6]} />))}
                                </BarChart>
                            </ResponsiveContainer>
                            : 
                                <Typography variant="h6" style={{ 
                                    textAlign: 'center', 
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    marginTop: 36 
                                }}> NO DATA</Typography>
                            }
                        </Box>
                </Grid>
                <Grid item xs={6}>
                        <Box style={{ width: "100%", height: 600, paddingBottom: 30 }}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>
                                Client Version Used
                            </Typography>
                            {(deviceUsageChartData && deviceUsageChartData.length) ? 
                                <ResponsiveContainer>
                                    <BarChart
                                        data={deviceVersionData}
                                        layout={'vertical'}
                                        margin={{
                                            top: 20, right: 30, left: 140, bottom: 10,
                                        }}
                                    >
                                        <XAxis type="number" />
                                        <YAxis dataKey="name" type="category" minTickGap={1} tick={<CustomizedAxisTick />} />
                                        <Tooltip />
                                        <Bar dataKey='count' fill={theme.palette.primary.main} />
                                    </BarChart>
                                </ResponsiveContainer>
                                : 
                                    <Typography variant="h6" style={{ 
                                        textAlign: 'center', 
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        marginTop: 36 
                                    }}> NO DATA</Typography>
                            }
                        </Box>
                </Grid>
            </Grid>
        </Card >

    )
}

export default DeviceActivityCard
