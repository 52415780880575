import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
    useTheme,
    Typography,
    Card,
    Box,
    Divider,

} from '@material-ui/core'
import {
    BarChart, Bar, Legend, ResponsiveContainer
} from 'recharts'
import PercentChangeText from '../../Text/PercentChangeText'
import TrackerSection from './TrackerSection';
import { getPrintableTimeWindow } from '@mc/common/components/Toggles/TimeFrameToggle'
import { textToIDFormat } from "../../../lib/strings";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 6,
        paddingTop: 20
    },
    cardContentBox: {
        paddingLeft: 20,
        paddingRight: 20,
    },

}));

//TODO: Should take arrays of objects to map the row data by keys. Probably safer
const TrackerInfoCard = ({
    headerText,
    subText,
    statisticNumber,
    percentChange,
    timeWindow,
    previous,
    current,
    trackerSections,
    onClickDataRow
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const [trackerInfoCardId] = React.useState(textToIDFormat(headerText,'card'),[headerText]);
    // Need to get 'last week' to be dynamic i.e. month, day
    return (
        <Card id={trackerInfoCardId} className={classes.card}>
            <Box className={classes.cardContentBox}>
                <Typography variant="h3" gutterBottom className={classes.cardText}>
                    {headerText}
                </Typography>
                {subText &&
                    <Box style={{ height: 50 }}>
                        <Typography color="textPrimary" variant="body2" className={classes.cardText}>
                            {subText}
                        </Typography>
                    </Box>
                }
                <Typography style={{ fontWeight: 600, paddingTop: 20 }} color="textPrimary" variant="body2" className={classes.cardText}>
                    {'Trackers neutralized in the ' + getPrintableTimeWindow(timeWindow)}
                </Typography>
                <Box id={trackerInfoCardId + '_neutralized_in_last_week_values'} style={{ display: 'flex' }}>
                    <Typography variant='h4' >
                        {statisticNumber}
                    </Typography>
                    <PercentChangeText score={percentChange} />
                </Box>
                <Box id={trackerInfoCardId + '_chart_bar_container'} data-prev={previous} data-current={current} style={{ width: "100%", height: 300, paddingTop: 10, paddingBottom: 20 }}>
                    <ResponsiveContainer>
                        <BarChart
                            data={[{ pv: previous, cu: current }]}
                        >
                            <Legend />
                            <Bar name="Previous Period" dataKey="pv" stackId="a" fill={theme.palette.primary.main} />
                            <Bar name="Current Period" dataKey="cu" stackId="b" fill={theme.palette.primary.light} />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>

            </Box>
            <Divider />
            {trackerSections && trackerSections.length > 0 &&
                trackerSections.map((trackerSection, index) => (
                    <TrackerSection
                        key={index}
                        parentId={trackerInfoCardId}
                        dataRows={trackerSection.dataRows}
                        title={trackerSection.title}
                        isLastSectiion={index === trackerSections.length - 1 ? true : false}
                        onClickDataRow={onClickDataRow}
                    />
                ))
            }
        </Card >
    )
}
TrackerInfoCard.propTypes = {
    headerText: PropTypes.string.isRequired,
    subText: PropTypes.string,
    statisticNumber: PropTypes.number.isRequired,
    percentChange: PropTypes.number.isRequired,
    timeWindow: PropTypes.string.isRequired,
    previous: PropTypes.number.isRequired,
    current: PropTypes.number,
    trackerSections: PropTypes.array,
    onClickDataRow: PropTypes.func,
}

export default TrackerInfoCard
