import React from 'react'
import {
    makeStyles,
    Card,
    Typography,
    Box
} from '@material-ui/core'
import MapGL, { NavigationControl, FullscreenControl, Source, Layer, Popup } from 'react-map-gl'
import { getGeoData, getDrilldown } from '../../../api/datavis';
import Axios from 'axios'
import { heat, heatPoint } from './mapLayers'
import { AuthenticationContext } from '../../../App/AuthenticationContext';
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

//There is a weird csp bug in mapbox that keeps it from playing nice with static page rendering in gatsby
// This is a magic spell that fixes it...
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default



const useStyles = makeStyles((theme) => ({
    card: {
        padding: 20,
        borderRadius: 6,

    },
    infoText: {
        paddingTop: 2,
        fontSize: 40,
        fontWeight: 600,
        lineHeight: "48px",
    },
    change: {
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "24px",
    },
}));

const formatDate = (date) => {
    const [day, month, year] = [date.getDate(), date.toLocaleString('en-US', { month: 'short' }), date.getFullYear()]
    return `${day} ${month} ${year}`
}

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}

const EmailAccessLocationCard = () => {
    const classes = useStyles()
    const date = new Date()
    const now = formatDate(date)
    const oneWeekAgo = formatDate(new Date(date.setDate(date.getDate() - 7)))
    const mapRef = React.useRef(null);
    const [authState,] = React.useContext(AuthenticationContext)
    const isMountedRef = useIsMountedRef()

    const [viewport, setViewport] = React.useState({
        width: '100%',
        height: 500,
        latitude: 40,
        longitude: -50,
        zoom: 2,
    })
    const [geoJson, setGeoJson] = React.useState(null)

    const initialGetGeoJson = () => {
        getGeoData(authState).then(response => {
            if(isMountedRef.current){
                Axios.get(response.data).then(resp => {
                    if(isMountedRef.current){
                        setGeoJson(resp.data)
                    }
                })
            }

        })
    }
    React.useEffect(initialGetGeoJson, [])

    const [drilldownPopUp, setDrilldownPopUp] = React.useState(null)
    const createDrilldownPopup = (drilldownData, coordinates) => {
        setDrilldownPopUp(
            <Popup
                latitude={coordinates[1]}
                longitude={coordinates[0]}
                closeButton={true}
                closeOnClick={false}
                onClose={() => setDrilldownPopUp(null)}
                anchor="top" >
                <ul style={{ margin: '0 0 0 10px', padding: 0 }}>
                    <li><b>Subject:</b> {drilldownData['subject_field']} </li>
                    <li><b>Recipients:</b>  {drilldownData['recipients']} </li>
                    <li><b>Sender:</b>  {drilldownData['from_field']} </li>
                    <li><b>IP:</b> {drilldownData['forwarded_ip_address']} </li>
                    <li><b>LatLong:</b>  {drilldownData['location']} </li>
                    <li><b>City:</b>  {drilldownData['city']} </li>
                    <li><b>Model:</b>  {drilldownData['device_model']} </li>
                    <li><b>OS:</b>  {drilldownData['os_name']} </li>
                    <li><b>Manufacturer:</b>  {drilldownData['device_manufacturer']} </li>
                    <li><b>Browser:</b>  {drilldownData['browser_name']} </li>
                    <li><b>Browser Version:</b>  {drilldownData['browser_version']} </li>
                </ul>
            </Popup>
        )
    }

    const onClick = event => {
        //Checks needed to prevent clicks outside of points from calling drilldown logic
        if (event.features && event.features.length > 0) {
            const feature = event.features[0]
            if (feature.properties && feature.properties.image_removal_id) {
                let coordinates = feature.geometry.coordinates.slice()
                getDrilldown(feature.properties.image_removal_id, authState).then(drilldown => {
                    createDrilldownPopup(drilldown.data, coordinates)
                })
            }
        }
    }

    return (
        <Card className={classes.card}>
            <Typography variant="h2" gutterBottom>
                Email Access Location
            </Typography>
            <Typography style={{ paddingBottom: 20 }} color="textPrimary" variant="body2">
                This dashboard displays potential leaks that were stopped by CyberGraph over the last 7 days.
            </Typography>
            <Box style={{
                verticalAlign: 'middle',
                lineHeight: 'normal',
                paddingBottom: 20
            }}>
                <Typography style={{ paddingBottom: 15, width: 'max-content' }} color="textPrimary" variant="body2">
                    {`${oneWeekAgo} / ${now}`}
                </Typography>
            </Box>
            <MapGL
                mapboxApiAccessToken={process.env.GATSBY_MAP_GL_KEY}
                {...viewport}
                onViewportChange={nextViewport => setViewport(nextViewport)}
                mapStyle="mapbox://styles/mapbox/light-v10"
                interactiveLayerIds={[heatPoint.id]}
                onClick={onClick}
                ref={mapRef}
            >
                {drilldownPopUp}

                {geoJson &&
                    <Source id="my-data" type="geojson" data={geoJson}>
                        <Layer {...heat} />
                        <Layer {...heatPoint} />
                    </Source>
                }
                <div style={{ position: 'absolute', right: 0 }}>
                    <NavigationControl />
                    <FullscreenControl container={document.querySelector('body')} />
                </div>

            </MapGL>
        </Card >

    )
}

export default EmailAccessLocationCard
