import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
    Typography,
    Card,
    Box,
} from '@material-ui/core'
import PercentChangeText from '../Text/PercentChangeText';
import { textToIDFormat } from "../../lib/strings";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '15px 20px ',
        borderRadius: 6,
    },

}));


const SingleStatCard = ({statisticNumber, statisticTitle, percentChange}) => {
    const classes = useStyles()

    const [cardId] = React.useState(textToIDFormat(statisticTitle,'values'),[statisticTitle]);

    return (
        <Card className={classes.card}>
            <Typography style={{ paddingBottom: 5, fontWeight: 600 }} color="textPrimary" variant="body2">
                {statisticTitle}
            </Typography>
            <Box id={cardId} style={{ display: 'flex' }}>
                <Typography variant='h4' >
                    {statisticNumber}
                </Typography>
                <PercentChangeText score={percentChange} />
            </Box>
        </Card >
    )
}
SingleStatCard.propTypes = {
    statisticTitle: PropTypes.string,
    statisticNumber: PropTypes.number,
    percentChange: PropTypes.number,
}

export default SingleStatCard

