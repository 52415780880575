export const parseRcptFields = (original_buckets) => {

    let processedMap = {}
    original_buckets.forEach(item => {
        let emailList = item.key.trim().split(/\s*,\s*/)
        emailList.forEach(email => {
            if (email) {
                if (!processedMap[email]) {
                    processedMap[email] = item.doc_count
                }
                else {
                    processedMap[email] += item.doc_count
                }
            }
        })
    })
    let processedArray = []
    Object.keys(processedMap).forEach(emailKey => {
        if (processedMap[emailKey])
            processedArray.push({ key: emailKey, doc_count: processedMap[emailKey] })
    })

    return processedArray

}

export const processSummaryData = (responseData) => {
    return {
        total: responseData.total_emails[0] || 0,
        totalperc: Math.ceil(responseData.total_emails[1]) || 0,
        potential: responseData.total_potential_spying_attempts[0] || 0,
        potentailperc: Math.ceil(responseData.total_potential_spying_attempts[1]) || 0,
        known: responseData.total_known_spying_attempts[0] || 0,
        knownperc: Math.ceil(responseData.total_known_spying_attempts[1]) || 0,

    }
}

export const processSummaryGraphData = (responseData) => {
    return {
        indv: parseInt(responseData.total_individual_trackers[0]),
        indvperc: Math.abs(responseData.total_individual_trackers[1]),
        indv_prev: parseInt(responseData.total_individual_trackers[2]),
        i_total: parseInt(responseData.total_individual_trackers[0]) +
            parseInt(responseData.total_individual_trackers[2]),

        unided: parseInt(responseData.total_unidentified_trackers[0]),
        unidedperc: Math.abs(responseData.total_unidentified_trackers[1]),
        unided_prev: parseInt(responseData.total_unidentified_trackers[2]),
        u_total: parseInt(responseData.total_unidentified_trackers[0]) +
            parseInt(responseData.total_unidentified_trackers[2]),

        bb: parseInt(responseData.total_behv_bulk_trackers[0]),
        bbperc: Math.abs(responseData.total_behv_bulk_trackers[1]),
        bb_prev: parseInt(responseData.total_behv_bulk_trackers[2]),
        b_total: parseInt(responseData.total_behv_bulk_trackers[0]) +
            parseInt(responseData.total_behv_bulk_trackers[2]),
    };
}

export const processBITrackerResponse = (responseData, fieldKey) => {
    let prev_comp_trackers = responseData.previous_window.aggregations && responseData.previous_window.aggregations[fieldKey]
        ? Object.entries(responseData.previous_window.aggregations[fieldKey].buckets).map(([k, v]) => {
            return { key: k, doc_count: v.doc_count }
        }) : []

    let curr_comp_trackers = responseData.current_window.aggregations && responseData.current_window.aggregations[fieldKey]
        ? Object.entries(responseData.current_window.aggregations[fieldKey].buckets).map(([k, v]) => {
            return { key: k, doc_count: v.doc_count }
        }) : []

    let current_window = curr_comp_trackers.length
        ? curr_comp_trackers.reduce((s, f) => s + f.doc_count, 0) : 0

    let previous_window = prev_comp_trackers.length
        ? prev_comp_trackers.reduce((s, f) => s + f.doc_count, 0) : 0

    return {
        data: curr_comp_trackers,

        current_window: current_window,

        previous_window: previous_window,

    }
}

export const getHeightAlt = (v, t) => {
    if (t === 0) return 0;
    return ((v / t) * 100);
}

export const getPercentage = (curr, prev) => {
    if (!curr || !prev || Object.is(curr, NaN) || Object.is(prev, NaN))
        return 0
    if (prev === 0)
        return 0

    return Math.abs(Math.round(100 * (curr - prev) / prev));
}

/**
 * Replaces tracker tool ids with actual names.
 * Modified here instead of api because of legacy frontend
 * dependence on api endpoint
 * @param buckets array of objects of form {key: <tracker_id>, doc_count: <count_of_instances>}
 * @param trackerNamesMap object mapping tracker id to tracker name
 * @returns array= of objects of form {key: <tracker_name>, doc_count: <count_of_instances>} 
 */
export const getTrackerNameFromKeys = (buckets, trackerNamesMap) => {
    return buckets.map((entry) =>{
        let trackerName = trackerNamesMap[entry.key] ? trackerNamesMap[entry.key] : "Unknown"
        return {
            ...entry, 
            key: trackerName
        }
    })
}