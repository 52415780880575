import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box, Grid } from '@material-ui/core'
import { AppContext, UPDATE_TIME_WINDOW, UPDATE_TRACKING_DATA, UPDATE_COMPETITIVE_DATA } from '../../App/AppContext'
import { getBIStats, getTopStatistics, getTrackedReplyStats } from '../../api/trackers'
import { getSummaryDashboard } from '../../api/dashboards'
import TimeFrameToggle from '@mc/common/components/Toggles/TimeFrameToggle'
import SingleStatCard from '@mc/common/components/Cards/SingleStatCard'
import TrackerInfoCard from '@mc/common/components/Cards/TrackerInfoCard/TrackerInfoCard'
import {
    parseRcptFields,
    processSummaryGraphData,
    processSummaryData,
    getHeightAlt,
    processBITrackerResponse,
    getPercentage,
    getTrackerNameFromKeys
} from '../utils/trackerDataUtils'
import { SearchContext, UPDATE_SEARCH_TERM } from '../SearchContext'
import { AuthenticationContext } from '../../App/AuthenticationContext'


const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
        '@media print': {
            width: 1000,
            margin: 20,
        }
    },
    printButton: {
        height: 32,
        marginBottom: 18,
        borderRadius: 6
    },

}));

const OverviewTab = ({ goToSearch }) => {
    const classes = useStyles()
    const componentRef = useRef()
    const [authState,] = React.useContext(AuthenticationContext)
    const [appState, appDispatch] = React.useContext(AppContext)
    const [, searchDispatch] = React.useContext(SearchContext)

    const [summaryGraphData, setSummaryGraphData] = React.useState(
        {
            indv: 0,
            indvperc: 0,
            indv_prev: 0,
            i_total: 0,

            unided: 0,
            unidedperc: 0,
            unided_prev: 0,
            u_total: 0,

            bb: 0,
            bbperc: 0,
            bb_prev: 0,
            b_total: 0,

        }
    )

    const getUpdateSummaryDashboard = () => {
        getSummaryDashboard(appState.timeWindow, authState).then(res => {
            appDispatch(
                {
                    type: UPDATE_TRACKING_DATA,
                    payload: processSummaryData(res.data)
                }
            )
            setSummaryGraphData(processSummaryGraphData(res.data))
        })
    }
    React.useEffect(getUpdateSummaryDashboard, [appState.timeWindow])

    const [graphHeights, setGraphHeight] = React.useState(
        {
            i_p: 0,
            i_c: 0,
            u_p: 0,
            u_c: 0,
            b_p: 0,
            b_c: 0
        }
    )
    React.useEffect(() => {
        setGraphHeight(
            {
                i_p: getHeightAlt(summaryGraphData.indv_prev, summaryGraphData.i_total),
                i_c: getHeightAlt(summaryGraphData.indv, summaryGraphData.i_total),
                u_p: getHeightAlt(summaryGraphData.unided_prev, summaryGraphData.u_total),
                u_c: getHeightAlt(summaryGraphData.unided, summaryGraphData.u_total),
                b_p: getHeightAlt(summaryGraphData.bb_prev, summaryGraphData.b_total),
                b_c: getHeightAlt(summaryGraphData.bb, summaryGraphData.b_total)
            }

        )
    }, [summaryGraphData])

    const getUpdateTrackedReplyStats = () => {
        getTrackedReplyStats(appState.timeWindow, authState).then(res => {
            appDispatch(
                {
                    type: UPDATE_COMPETITIVE_DATA,
                    payload: {
                        total_conversations: res.data.total_conversations[0] || 0,
                        totalperc: Math.ceil(res.data.total_conversations[1]) || 0
                    }
                }
            )
        })

    }
    React.useEffect(getUpdateTrackedReplyStats, [appState.timeWindow])

    const [topStats, setTopStats] = React.useState(
        {
            top_targets_individual: null,
            top_targets_unidentified: null,
            top_targets_bulk_behav: null,
            top_sending_domains_individual: null,
            top_sending_domains_unidentified: null,
            top_sending_domains_bulk_behav: null,
            top_tracking_tools_individual: null,
            top_tracking_tools_bulk_behav: null,
        }
    )

    const getUpdateTopStats = () => {
        let mounted = true
        getTopStatistics(appState.timeWindow, authState).then(response => {
            if(mounted){
                setTopStats({
                    top_targets_individual: parseRcptFields(response.data.sales_stats?response.data.sales_stats['rcpt_to_field.raw'].buckets:[]),
                    top_sending_domains_individual:response.data.sales_stats? response.data.sales_stats['from_field.raw'].buckets:[],
                    top_tracking_tools_individual: getTrackerNameFromKeys(response.data.sales_stats?response.data.sales_stats['blacklist_id'].buckets:[], response.data.sales_trackers),

                    top_targets_bulk_behav: parseRcptFields(response.data.marketing_stats?response.data.marketing_stats['rcpt_to_field.raw'].buckets:[]),
                    top_sending_domains_bulk_behav: response.data.marketing_stats?response.data.marketing_stats['from_field.raw'].buckets:[],
                    top_tracking_tools_bulk_behav: getTrackerNameFromKeys(response.data.marketing_stats?response.data.marketing_stats['blacklist_id'].buckets:[], response.data.marketing_trackers),

                    top_targets_unidentified: parseRcptFields(response.data.anon_stats?response.data.anon_stats['rcpt_to_field.raw'].buckets:[]),
                    top_sending_domains_unidentified: response.data.anon_stats?response.data.anon_stats['from_field.raw'].buckets:[],

                })
            }
        })
        return () => mounted = false;
    }

    React.useEffect(getUpdateTopStats, [appState.timeWindow])

    const [competitorTrackers, setCompetitorTrackers] = React.useState({
        data: [],
        current_window: 0,
        previous_window: 0,
    })
    const [keywordTrackers, setKeywordTrackers] = React.useState({
        data: [],
        current_window: 0,
        previous_window: 0,
    })
    const [employeeTrackers, setEmployeeTrackers] = React.useState({
        data: [],
        current_window: 0,
        previous_window: 0,
    })
    const getUpdateBIStats = () => {
        getBIStats(appState.timeWindow, authState).then(res => {
            setCompetitorTrackers(processBITrackerResponse(res.data, 'from_field.raw'))
            setKeywordTrackers(processBITrackerResponse(res.data, 'subject_field'))
            setEmployeeTrackers(processBITrackerResponse(res.data, 'rcpt_to_field.raw'))

        })
    }
    React.useEffect(getUpdateBIStats, [appState.timeWindow])


    const handleTimeWindow = (newTimeWindow) => {
        appDispatch({
            type: UPDATE_TIME_WINDOW,
            payload: newTimeWindow
        })
    }

    const handleClickSearch = (dataRow) => {
        searchDispatch(
            {
                type: UPDATE_SEARCH_TERM,
                payload: dataRow.key,
            }
        )
        if (goToSearch) {
            goToSearch()
        }
    }


    return (
        <>
            <Box component="span">
                <TimeFrameToggle timeWindow={appState.timeWindow} onChange={handleTimeWindow} />
            </Box>
            <Box className={classes.summaryGrid} ref={componentRef}>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={3}>
                        <SingleStatCard
                            statisticTitle='Total Emails Scanned'
                            statisticNumber={appState.trackingData.total !== null ? parseInt(appState.trackingData.total) : 0}
                            percentChange={appState.trackingData.totalperc !== null ? parseInt(appState.trackingData.totalperc) : 0} />
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <SingleStatCard
                            statisticTitle='Replies To Tracked Emails'
                            statisticNumber={appState.competitiveTrackingData.total_conversations !== null ? parseInt(appState.competitiveTrackingData.total_conversations) : 0}
                            percentChange={appState.competitiveTrackingData.totalperc !== null ? parseInt(appState.competitiveTrackingData.totalperc) : 0} />
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <SingleStatCard
                            statisticTitle='Known Attempts Secured'
                            statisticNumber={appState.trackingData.known !== null ? parseInt(appState.trackingData.known) : 0}
                            percentChange={appState.trackingData.knownperc !== null ? parseInt(appState.trackingData.knownperc) : 0} />
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <SingleStatCard
                            statisticTitle='Potential Attempts Secured'
                            statisticNumber={appState.trackingData.potential !== null ? parseInt(appState.trackingData.potential) : 0}
                            percentChange={appState.trackingData.potentailperc !== null ? parseInt(appState.trackingData.potentailperc) : 0} />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TrackerInfoCard
                            headerText='Individual Trackers'
                            subText='Tracks locations, device fingerprints, and email forwards.'
                            previous={graphHeights.i_p}
                            current={graphHeights.i_c}
                            timeWindow={appState.timeWindow}
                            onClickDataRow={handleClickSearch}
                            statisticNumber={summaryGraphData.indv || 0}
                            percentChange={summaryGraphData.indvperc || 0}
                            trackerSections={
                                [
                                    {
                                        title: 'Top Targets',
                                        dataRows: topStats.top_targets_individual || []
                                    },
                                    {
                                        title: 'Top Sending Domains',
                                        dataRows: topStats.top_sending_domains_individual || []
                                    },
                                    {
                                        title: 'Top Tracking Tools',
                                        dataRows: topStats.top_tracking_tools_individual || []
                                    }
                                ]
                            } />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TrackerInfoCard
                            headerText='Behavior & Bulk Marketing'
                            subText='Tracks locations, device fingerprints.'
                            previous={graphHeights.b_p || 0}
                            current={graphHeights.b_c || 0}
                            onClickDataRow={handleClickSearch}
                            timeWindow={appState.timeWindow}
                            statisticNumber={summaryGraphData.bb || 0}
                            percentChange={appState.bbperc || 0}
                            trackerSections={
                                [
                                    {
                                        title: 'Top Targets',
                                        dataRows: topStats.top_targets_bulk_behav || []
                                    },
                                    {
                                        title: 'Top Sending Domains',
                                        dataRows: topStats.top_sending_domains_bulk_behav || []
                                    },
                                    {
                                        title: 'Top Tracking Tools',
                                        dataRows: topStats.top_tracking_tools_bulk_behav || []
                                    }
                                ]
                            } />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TrackerInfoCard
                            headerText='Other Trackers'
                            subText='Tracks locations, device fingerprints.'
                            previous={graphHeights.u_p}
                            current={graphHeights.u_c}
                            onClickDataRow={handleClickSearch}
                            timeWindow={appState.timeWindow}
                            statisticNumber={summaryGraphData.unided || 0}
                            percentChange={appState.unidedperc || 0}
                            trackerSections={
                                [
                                    {
                                        title: 'Top Targets',
                                        dataRows: topStats.top_targets_unidentified || []
                                    },
                                    {
                                        title: 'Top Sending Domains',
                                        dataRows: topStats.top_sending_domains_unidentified || []
                                    },
                                ]
                            } />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TrackerInfoCard
                            headerText='Competitor Domains'
                            previous={
                                getHeightAlt(
                                    competitorTrackers.previous_window,
                                    competitorTrackers.previous_window + competitorTrackers.current_window
                                )
                            }
                            current={
                                getHeightAlt(
                                    competitorTrackers.current_window,
                                    competitorTrackers.previous_window + competitorTrackers.current_window
                                )
                            }
                            onClickDataRow={handleClickSearch}
                            timeWindow={appState.timeWindow}
                            statisticNumber={competitorTrackers.current_window || 0}
                            percentChange={getPercentage(
                                competitorTrackers.current_window,
                                competitorTrackers.previous_window
                            ) || 0}
                            trackerSections={
                                [
                                    {
                                        title: 'Domains Tracked',
                                        dataRows: competitorTrackers.data || []
                                    },
                                ]
                            } />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TrackerInfoCard
                            headerText='Subject Line Keywords'
                            previous={
                                getHeightAlt(
                                    keywordTrackers.previous_window,
                                    keywordTrackers.previous_window + keywordTrackers.current_window
                                )
                            }
                            current={
                                getHeightAlt(
                                    keywordTrackers.current_window,
                                    keywordTrackers.previous_window + keywordTrackers.current_window
                                )
                            }
                            onClickDataRow={handleClickSearch}
                            timeWindow={appState.timeWindow}
                            statisticNumber={keywordTrackers.current_window || 0}
                            percentChange={getPercentage(
                                keywordTrackers.current_window,
                                keywordTrackers.previous_window
                            ) || 0}
                            trackerSections={
                                [
                                    {
                                        title: 'Keywords Tracked',
                                        dataRows: keywordTrackers.data || []
                                    },
                                ]
                            } />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TrackerInfoCard
                            headerText='Key Employees'
                            previous={
                                getHeightAlt(
                                    employeeTrackers.previous_window,
                                    employeeTrackers.previous_window + employeeTrackers.current_window
                                )
                            }
                            current={
                                getHeightAlt(
                                    employeeTrackers.current_window,
                                    employeeTrackers.previous_window + employeeTrackers.current_window
                                )
                            }
                            onClickDataRow={handleClickSearch}
                            timeWindow={appState.timeWindow}
                            statisticNumber={employeeTrackers.current_window || 0}
                            percentChange={getPercentage(
                                employeeTrackers.current_window,
                                employeeTrackers.previous_window
                            ) || 0}
                            trackerSections={
                                [
                                    {
                                        title: 'Employees Tracked',
                                        dataRows: employeeTrackers.data || []
                                    },
                                ]
                            } />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
OverviewTab.propTypes = {
    goToSearch: PropTypes.func,
}

export default OverviewTab
