import React from 'react'
import {
    Card,
    Box,
    makeStyles,
    useTheme,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    LinearProgress,
    Button

} from '@material-ui/core'
import { deleteExportRecord, getAllCSVRequests, getExportRecord } from '../../api/trackers'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { AuthenticationContext } from '../../App/AuthenticationContext'

const SPYING_TYPE_MAP = {
    high: 'Individual Targeted',
    medium: 'Behavioral',
    low: 'Bulk Marketing',
    Conversations: 'Conversations',
}

const useStyles = makeStyles((theme) => ({
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
    },
    cardContent: {
        paddingRight: 20,
        paddingLeft: 20,
    },

    searchBar: {
        width: 350,
        height: 32,
        borderRadius: '6px',
        border: `1px solid ${theme.colors[2]}`,
        boxShadow: 'none'
    },
    hoverable: {
        '&:hover': {
            background: theme.backgrounds[1],
        },
    },
    tableRowHeader: {
        fontWeight: 600,
    },
    tableCell: {
        maxWidth: 300,
        padding: '16px 20px'
    }

}))

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}

const ExportsTab = () => {
    const classes = useStyles()
    const theme = useTheme()
    const [authState,] = React.useContext(AuthenticationContext)
    let [csvDataRegistries, setCsvDataRegistries] = React.useState([])
    let [currentSlice, setCurrentSlice] = React.useState(10)
    let [loading, setLoading] = React.useState(true)
    const isMountedRef = useIsMountedRef()

    const handleDeleteExport = (exportId) => {
        deleteExportRecord(exportId, authState).then(response => {
            if (response.status === 200) {
                loadExports()
            }
        })
    }
    const handleDownloadExport = (s3Url) => {

        getExportRecord(s3Url, authState).then(response => {
            window.open(response.data)
        })
    }

    const loadExports = () => {
        getAllCSVRequests(authState).then(response => {
            if(isMountedRef.current){
                let incomingExports = response.data.csv_exports.map(exp => {

                    exp.timestamp_from = exp.timestamp_from.length > 10 ?
                        moment.unix(exp.timestamp_from / 1000).format("MM/DD/YYYY")
                        :
                        exp.timestamp_from = moment.unix(exp.timestamp_from).format("MM/DD/YYYY")

                    exp.timestamp_to = exp.timestamp_to.length > 10 ?
                        exp.timestamp_to = moment.unix(exp.timestamp_to / 1000).format("MM/DD/YYYY")
                        :
                        exp.timestamp_to = moment.unix(exp.timestamp_to).format("MM/DD/YYYY")

                    exp.timestamp_time = moment(exp.timestamp).local().format("h:mm A")
                    exp.timestamp = moment(exp.timestamp).format("MM/DD/YYYY")
                    exp.dateTime = `${exp.timestamp} ${exp.timestamp_time}`
                    exp.name = `${exp.first_name} ${exp.last_name} ${exp.user_email}`
                    exp.spyingType = exp.spying ? SPYING_TYPE_MAP[exp.spying] : 'Unidentified'

                    if(exp.status === 'processed') {
                        exp.actions = (<Box>
                            <Button onClick={() => handleDownloadExport(exp.s3_url)}>
                                <FontAwesomeIcon style={{ color: theme.palette.primary.main }} icon={faCloudDownload} />
                            </Button>
                            <Button onClick={() => handleDeleteExport(exp.csv_request_id)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Box>)
                    } else {
                        exp.actions = (<Box></Box>)
                    }

                    return exp
                })
                setCsvDataRegistries(incomingExports)
                setCurrentSlice(incomingExports.length < 10 ? incomingExports.length : 10)
                setLoading(false)
            }
        })
    }

    React.useEffect(loadExports, [])

    const colHeaders = [
        { displayName: 'Date & Time', key: 'dateTime' },
        { displayName: 'User', key: 'name' },
        { displayName: 'State', key: 'status' },
        { displayName: 'Search Keywords', key: 'search' },
        { displayName: 'Spying Type', key: 'spyingType' },
        { displayName: 'Time Range', key: 'dateTime' },
        { displayName: 'Actions', key: 'actions' },
    ]

    const colKeys = colHeaders.map(colHeader => { return colHeader.key })


    const loadMore = () => {
        let sliceIncrementAmount = 10
        if (csvDataRegistries.length < currentSlice + 10) {
            sliceIncrementAmount = currentSlice + 10 - csvDataRegistries.length
        }
        setCurrentSlice(currentSlice + sliceIncrementAmount)
    }


    return (
        <Card className={classes.card}>
            {loading && <LinearProgress style={{ margin: 20 }} />}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {colHeaders.map((colHeader, index) => {
                                return (
                                    <TableCell key={index} align={'center'} className={classes.tableCell}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            classes={{ root: classes.tableRowHeader }}
                                        >
                                            {colHeader.displayName}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {csvDataRegistries && csvDataRegistries.slice(0, currentSlice).map((row, index) => (
                            <TableRow
                                key={index}
                                className={classes.hoverable}
                            >
                                {colKeys.map((key, index) => {
                                    return (
                                        <TableCell key={index} align={'center'} className={classes.tableCell}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                            >
                                                {row[key]}
                                            </Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {currentSlice < csvDataRegistries.length && <Box style={{ textAlign: 'center', paddingTop: 20 }}>
                <Button onClick={() => loadMore()} color="primary">Load More</Button>
            </Box>
            }
        </Card >

    )
}
export default ExportsTab