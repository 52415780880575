export const heat = {
    id: "heat",
    type: "heatmap",
    maxzoom: 9,
    paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        "heatmap-weight": [
            "interpolate",
            ["linear"],
            ["get", "mag"],
            0, 0,
            6, 1
        ],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 1,
            9, 3
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0, "rgba(33,102,172,0)",
            0.2, "#123D6A",
            0.4, "#2F5FA7",
            0.6, "#0093D3",
            0.8, "#5FC7BE",
            1, "#DDECC7"
        ],
        // Adjust the heatmap radius by zoom level
        "heatmap-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 10,
            9, 50
        ],
        // Transition from heatmap to circle layer by zoom level
        "heatmap-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, 1,
            9, 0
        ],
    }
}
export const heatPoint = {
    id: "heat-point",

    type: "circle",
    minzoom: 7,
    paint: {
        // Size circle radius by earthquake magnitude and zoom level
        "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1, 1,
                6, 4
            ],
            16, [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1, 5,
                6, 50
            ]
        ],
        // Color circle by earthquake magnitude
        "circle-color": [
            "interpolate",
            ["linear"],
            ["get", "mag"],
            50, "rgba(33,102,172,0)",
            200, "#123D6A",
            350, "#2F5FA7",
            500, "#0093D3",
            650, "#5FC7BE",
            800, "#DDECC7"
        ],

        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
        // Transition from heatmap to circle layer by zoom level
        "circle-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, 0,
            8, 1
        ]
    }
}