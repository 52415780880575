import Axios from "axios";
import { getRegionalizedUrl, TRACKING_API } from "@mc/common/lib/url-utils";
import {
    encodeUrlParams
} from "@mc/common/lib/url-params";

export const getTrackedReplyStats = (time_window, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_tracked_reply_stats',
        params: {
            time_window: time_window,
            end_time: null,
            start_time: null,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const getTopStatistics = (time_window, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_top_stats',
        params: {
            time_window: time_window,
            end_time: null,
            start_time: null,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const getBlockedTrackerData = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_blocked_tracker_data',
        params: {
            v: 1,
            start_timestamp: null,
            end_timestamp: null,
            page_number: 1,
            per_page: 100,
            search: '',
            risk_level: null,
            inbound_outbound: 'inbound',
            sort_timestamp: null,
            sort_id: null,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const getBIStats = (time_window, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_tracked_stats',
        params: {
            time_window: time_window,
            end_time: null,
            start_time: null,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const getOverview = (start_timestamp, end_timestamp, page, per_page, search_term, risk_level, sort_timestamp, sort_id, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_blocked_tracker_data',
        // Encode as form data
        data: encodeUrlParams({
            user_email: authState.email || '',
            session: authState.session || '',
            start_timestamp: start_timestamp || null,
            end_timestamp: end_timestamp || null,
            page_number: page,
            per_page: per_page,
            search: search_term || '',
            risk_level: risk_level,
            inbound_outbound: 'inbound',
            sort_timestamp: sort_timestamp,
            sort_id: sort_id
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const  requestCSV = (start_timestamp, end_timestamp, page, per_page, loading_more, search_term, risk_level, inbound_outbound, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/csv_export_request',
        data: encodeUrlParams({
            user_email: authState.email || '',
            session: authState.session || '',
            start_timestamp: start_timestamp,
            end_timestamp: end_timestamp,
            page_number: page,
            per_page: per_page,
            loading_more: loading_more,
            search: search_term || '',
            risk_level: risk_level,
            inbound_outbound: inbound_outbound || 'inbound',
        }),     
        crossdomain: true,   
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const getAllCSVRequests = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/csv_export_current_requests',
        params: {
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const getExportRecord = (s3Url, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_csv_file_download',
        data: encodeUrlParams({
            user_email: authState.email || '',
            session: authState.session || '',
            file_name: s3Url
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const deleteExportRecord = (requestId, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/delete_export_record',
        data: encodeUrlParams({
            user_email: authState.email || '',
            session: authState.session || '',
            csv_request_id: requestId
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}