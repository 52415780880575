import Axios from "axios";
import { getRegionalizedUrl, TRACKING_API } from "@mc/common/lib/url-utils";

export const getGeoData = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_data_visualization_map',
        params: {
            user_email: authState.email,
            session: authState.session,
        },
    })
}

export const getData = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_data_visualization_table',
        params: {
            user_email: authState.email,
            session: authState.session,
        },
    })
}

export const getDrilldown = (image_fetch_id, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/get_image_fetch_data',
        params: {
            user_email: authState.email,
            session: authState.session,
            image_fetch_id: image_fetch_id,
        },
    })
}
