import React from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    Box,
    makeStyles,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    LinearProgress,
    Button,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    TableBody

} from '@material-ui/core'

import SearchBar from 'material-ui-search-bar'

const useStyles = makeStyles((theme) => ({
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
    },
    cardContent: {
        paddingRight: 20,
        paddingLeft: 20,
    },
    searchBar: {
        width: 350,
        height: 32,
        borderRadius: '6px',
        border: `1px solid ${theme.colors[2]}`,
        boxShadow: 'none'
    },
    hoverable: {
        '&:hover': {
            background: theme.backgrounds[1],
        },
    },
    tableRowHeader: {
        fontWeight: 600,
    },
    tableCell: {
        maxWidth: 300,
        padding: '16px 20px'
    }

}))

//TODO: possible support for pagination?
const SearchTableCard = ({
    onChange,
    onSearch,
    colHeaders,
    value,
    filterDropdownOptions,
    filterDropdownHandleChange,
    filterDropdownValue,
    loading,
    loadMore,
    rows,
}) => {
    const classes = useStyles()
    const [searchValue, setSearchValue] = React.useState('')

    const handleChangeSearchValue = (v) => {
        if (onChange) {
            onChange(v)
        } else {
            setSearchValue(v)
        }
    }

    const handleExecuteSearch = () => {
        if (onSearch) {
            onSearch()
        }
    }
    const colKeys = colHeaders ? colHeaders.map(colHeader => { return colHeader.key }) : []

    return (

        <Card className={classes.card}>
            <Box style={{ paddingBottom: 20, display: 'flex', justifyContent: 'space-between' }} className={classes.cardContent}>
                <SearchBar
                    value={value ? value : searchValue}
                    onChange={(newValue) => handleChangeSearchValue(newValue)}
                    onRequestSearch={() => handleExecuteSearch()}
                    className={classes.searchBar}
                />
                {filterDropdownOptions && filterDropdownHandleChange &&
                    <FormControl variant="outlined" style={{ minWidth: 150 }}>
                        <InputLabel id="search-filter-select-outlined-label">Filter</InputLabel>
                        <Select
                            labelId="search-filter-select-outlined-label"
                            id="search-filter-select-outlined"
                            value={filterDropdownValue}
                            onChange={filterDropdownHandleChange}
                            label="Age"
                            style={{ fontSize: '14px' }}
                        >
                            {filterDropdownOptions.map((dropdownItem, index) => (
                                <MenuItem key={index} value={dropdownItem.value}>{dropdownItem.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

            </Box>
            <Divider />
            {loading && (
                <LinearProgress style={{ margin: 20 }} />
            )}
            {rows.length > 0 && !loading && <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {colHeaders && colHeaders.map((colHeader, index) => {
                                return (
                                    <TableCell key={index} className={classes.tableCell}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary"
                                            classes={{ root: classes.tableRowHeader }}
                                        >
                                            {colHeader.displayName}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                className={classes.hoverable}
                            >
                                {colKeys.map((key, index) => {
                                    return (
                                        <TableCell key={index} className={classes.tableCell}>
                                            <Typography
                                                variant="subtitle2"
                                                color="textPrimary"
                                            >
                                                {row[key]}
                                            </Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
            {rows.length > 0 && loadMore &&
                <Box style={{ textAlign: 'center', paddingTop: 20 }}>
                    <Button id='search-table-card-load-more' onClick={() => loadMore()} color="primary">Load More</Button>
                </Box>
            }
        </Card >
    )
}
SearchTableCard.propTypes = {
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    colHeaders: PropTypes.array,
    value: PropTypes.string,
    filterDropdownOptions: PropTypes.array,
    filterDropdownHandleChange: PropTypes.func,
    filterDropdownValue: PropTypes.string,
    loading: PropTypes.bool,
    loadMore: PropTypes.func,
    rows: PropTypes.array,
}
export default SearchTableCard
