import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
    Typography,
    Box,
    Divider,
    Button,

} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronUp,
    faChevronDown
} from '@fortawesome/pro-light-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import classnames from 'classnames'
import { textToIDFormat } from "../../../lib/strings";


const useStyles = makeStyles((theme) => ({
    cardContentBox: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    trackerDataText: {
        paddingTop: 12,
        paddingBottom: 12,
        display: 'inline-block',

    },
    trackerCountText: {
        float: 'right',
        display: 'inline-block',
        paddingTop: 8,
        paddingBottom: 8,
    },
    hoverable: {
        '&:hover': {
            background: theme.backgrounds[1],
            cursor: 'pointer'
        },
    },

}));

const TrackerSection = ({dataRows, title, onClickDataRow, isLastSection, parentId}) => {
    const classes = useStyles()

    const [viewMore, setViewMore] = React.useState(false)

    const [trackerValueId] = React.useState(() => parentId ? textToIDFormat(parentId, title) : '', [parentId])

    const toggleViewMore = () => {
        let current = viewMore
        setViewMore(!current)
    }

    const collapsedRowCount = dataRows.length >= 3 ? 3 : dataRows.length

    const visibleDataRows = viewMore ? dataRows : dataRows.slice(0, collapsedRowCount)

    return (
        <Box id={trackerValueId}>
            {title &&
                <>
                    <Box className={classes.cardContentBox}>
                        <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2" className={classes.trackerDataText}>
                            {title}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            }
            {visibleDataRows && visibleDataRows.length > 0 ? visibleDataRows.map((dataRow, index) => (
                <div key={index}>
                    <Box className={classnames(classes.cardContentBox, classes.hoverable)}
                        onClick={() => {
                            if (onClickDataRow) {
                                onClickDataRow(dataRow)
                            }
                        }}
                    >
                        <Typography color="textPrimary" variant="body2" className={classes.trackerDataText}>
                            {dataRow.key}
                        </Typography>
                        <Box component="span">
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2" className={classes.trackerCountText}>
                                {dataRow.doc_count}
                                <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faChevronRight}></FontAwesomeIcon>
                            </Typography>

                        </Box>

                    </Box>

                    <Divider />
                </div>
            )) :
                <Typography style={{ paddingLeft: 20 }} className={classes.trackerDataText} variant='body2'>No Data</Typography>
            }
            {visibleDataRows && visibleDataRows.length > 0 &&
                <Box style={{ textAlign: 'right', padding: '12px 20px 32px 20px' }} className={classes.cardContentBox}>
                    <Button id='tracker-section-view-more-less' onClick={toggleViewMore} style={{ padding: 0, textTransform: 'none' }} color="primary">
                        {viewMore ? 'View Less' : 'View More'}
                        <FontAwesomeIcon
                            style={{ marginLeft: 5, fontSize: 10 }}
                            icon={viewMore ? faChevronUp : faChevronDown}></FontAwesomeIcon>

                    </Button>
                </Box>
            }
            {!isLastSection && <Divider />}
        </Box>
    )
}
TrackerSection.propTypes = {
    dataRows: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    onClickDataRow: PropTypes.func,
    isLastSection: PropTypes.bool
}

export default TrackerSection
