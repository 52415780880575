import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
    Box,
    Button,
    Grid,
    Link,
    Snackbar
} from '@material-ui/core'
import SearchTableCard from '@mc/common/components/Cards/SearchTableCard'
import { getOverview, requestCSV } from '../../api/trackers'
import moment from 'moment'
import { SearchContext, UPDATE_SEARCH_TERM } from '../SearchContext'
import { AuthenticationContext } from '../../App/AuthenticationContext'
import MuiAlert from '@material-ui/lab/Alert'

const RISK_LEVELS = {
    high: 'Individual Targeted',
    medium: 'Behavioral',
    low: 'Bulk Marketing',
}

const useStyles = makeStyles(() => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
    },
    exportButton: {
        height: 32,
        marginBottom: 18,
        borderRadius: 6
    },
    searchableText: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    snackbarLinkText: {
        '&:hover': {
            cursor: 'pointer',
            color: '#EEE'
        },
        color: 'white',
        textDecoration: 'underline'
    }

}));

const defaultSearchParams = {
    inputSearch: '',
    riskLevel: '',
    sortTimestamp: null,
    sortId: null,
    tableLoading: true,
    currentPage: 1,
}

const FROM_DATE_MOMENT = moment().subtract(1, 'months');
const FROM_DATE = moment().subtract(1, 'months').format('YYYY/MM/DD');
const TO_DATE_MOMENT = moment();
const TO_DATE = moment().format('YYYY/MM/DD');
const PER_PAGE = 100

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}


const SearchTab = ({ goToExports }) => {

    const classes = useStyles()

    const [authState,] = React.useContext(AuthenticationContext)
    const [searchParams, setSearchParams] = React.useState(defaultSearchParams)
    const [searchState, searchDispatch] = React.useContext(SearchContext)
    const [toastOpen, setToastOpen] = React.useState(false)
    const isMountedRef = useIsMountedRef()

    const [loading, setLoading] = React.useState(false)

    const colHeaders = [
        { displayName: 'Sender', key: 'sender' },
        { displayName: 'Recipient', key: 'recipient' },
        { displayName: 'Subject', key: 'subject' },
        { displayName: 'Tool', key: 'tool' },
        { displayName: 'Spying Type', key: 'spyingType' },
        { displayName: 'Date & Time (UTC)', key: 'dateTime' },
    ]

    const [rows, setRows] = React.useState([])
    const [blockedMessages, setBlockedMessages] = React.useState([])
    const [blacklistMap, setBlacklistMap] = React.useState({})
    const [blacklistDescriptionMap, setBlacklistDescriptionMap] = React.useState({})

    const createSearchableText = (term) => {
        return (
            <Link className={classes.searchableText} onClick={() => handleAutoSearch(term)} underline='none'>
                {term}
            </Link>
        )
    }

    const processSearchData = (searchData) => {

        let incomingBlacklistMap = searchData.blacklist_map || {}
        let newBlacklistMap = { ...incomingBlacklistMap, ...blacklistMap }
        setBlacklistMap(newBlacklistMap)

        let incomingBlacklistDescriptionMap = searchData.blacklist_description || {}
        let newBlacklistDescriptionMap = { ...incomingBlacklistDescriptionMap, ...blacklistDescriptionMap }
        setBlacklistDescriptionMap(newBlacklistDescriptionMap)

        let incomingMessages = searchData.id_email_map ? Object.values(searchData.id_email_map) : []
        let newMessages = [...blockedMessages, ...incomingMessages]
        setBlockedMessages(newMessages)

        let incomingRows = incomingMessages.map(message => {
            let blacklistMatch = newBlacklistMap[message['blacklist_id']] || {}
            let tool = blacklistMatch['tracker_name'] || 'Unknown'
            let type = blacklistMatch['risk_level'] ? RISK_LEVELS[blacklistMatch['risk_level']] : 'Unknown'

            return {
                sender: createSearchableText(message['from_field']) || 'Unknown',
                //I think the intention in the legacy code was to clean trailing commas, so I simplified the exp
                recipient: message['rcpt_to_field'] ? createSearchableText(message['rcpt_to_field'].replace(/(^\s*,)|(,\s*$)/g, '')) : 'Unknown',
                subject: createSearchableText(message['subject_field']) || 'Unknown',
                tool: tool,
                spyingType: type,
                dateTime: new Date(message['timestamp']).toUTCString(),
            }
        })
        let currentRows = rows
        setRows([...currentRows, ...incomingRows])

    }

    const fetchSearchResults = (searchTerm, currentPage, itemsPerPage, riskLevel, sortTimestamp, sortId, fromDate, toDate) => {
        setLoading(true)
        getOverview(
            fromDate,
            toDate,
            currentPage,
            itemsPerPage,
            searchTerm,
            riskLevel,
            sortTimestamp,
            sortId,
            authState
        ).then(response => {
            if(isMountedRef.current){
                let currSearchParams = searchParams
                setSearchParams(
                    {
                        ...currSearchParams,
                        sortTimestamp: response.data.sort_timestamp,
                        sortId: response.data.sort_id,

                    }
                )

                processSearchData(response.data)
                setLoading(false)
            }
        })
    }

    const clearResults = () => {
        setRows([])
        setBlockedMessages([])
        setBlacklistMap({})
        setBlacklistDescriptionMap({})
        setSearchParams(defaultSearchParams)
    }

    const handleManualSearch = () => {
        clearResults()
        fetchSearchResults(
            searchState.searchTerm,
            searchParams.currentPage,
            PER_PAGE,
            searchParams.riskLevel,
            searchParams.sortTimestamp,
            searchParams.sortId,
            FROM_DATE,
            TO_DATE
        )
    }
    const setSearchTerm = (term) => {
        searchDispatch(
            {
                type: UPDATE_SEARCH_TERM,
                payload: term,
            }
        )
    }
    const handleAutoSearch = (term) => {
        clearResults()
        setSearchTerm(term)
        fetchSearchResults(
            term,
            searchParams.currentPage,
            PER_PAGE,
            searchParams.riskLevel,
            searchParams.sortTimestamp,
            searchParams.sortId,
            FROM_DATE,
            TO_DATE
        )

    }

    const loadMore = () => {
        let nextPage = searchParams.currentPage + 1
        setSearchParams({
            ...searchParams,
            currentPage: nextPage,
        })
        if (searchParams.sortTimestamp) {
            fetchSearchResults(
                searchState.searchTerm,
                nextPage,
                PER_PAGE,
                searchParams.riskLevel,
                searchParams.sortTimestamp,
                searchParams.sortId,
                FROM_DATE,
                TO_DATE
            )
        }
    }

    const initialTabLoadSearch = () => {
        if (searchState.searchTerm) {
            handleAutoSearch(searchState.searchTerm)
        }
    }

    React.useEffect(initialTabLoadSearch, [])

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setToastOpen(false)
    }

    const exportButtonClick = () => {
        requestCSV(FROM_DATE_MOMENT.unix(), TO_DATE_MOMENT.unix(), searchParams.currentPage, PER_PAGE,
                loading, searchState.searchTerm, searchParams.riskLevel, "inbound", authState)
            .then(function (response) {
                setToastOpen(true);
            })
    }

    return (
        <>
            <Box component="span">
                <Button id='btn_export' className={classes.exportButton} variant="contained" color="primary"
                        onClick={exportButtonClick}>
                    Export Data
                </Button>
                <Snackbar open={toastOpen} autoHideDuration={10000} onClose={handleCloseToast}>
                <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleCloseToast}>
                    Your data export is processing. We'll email you the results when they're ready. 
                    <br/>
                    You can also check the{" "}
                    <Link className={classes.snackbarLinkText} onClick={goToExports}>
                        Exports tab
                    </Link>.
                </MuiAlert >
            </Snackbar>
            </Box>
            <Box className={classes.summaryGrid}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SearchTableCard
                            rows={rows}
                            loading={loading}
                            colHeaders={colHeaders}
                            onSearch={handleManualSearch}
                            onChange={setSearchTerm}    
                            value={searchState.searchTerm}
                            loadMore={loadMore}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

SearchTab.propTypes = {
    goToExports: PropTypes.func,
}
export default SearchTab
