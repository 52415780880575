import React from 'react'
import PageHeader from '@mc/common/components/Page/PageHeader'
import {Box} from '@material-ui/core'
import Tabs from '@mc/common/components/Tabs/Tabs'
import OverviewTab from './tabs/OverviewTab'
import SearchTab from './tabs/SearchTab'
import ReconBlockedTab from './tabs/ReconBlockedTab/ReconBlockedTab'
import ExportsTab from './tabs/ExportsTab'
import SearchContextProvider from './SearchContext'
import {AuthenticationContext} from "../App/AuthenticationContext";


const Trackers = ({locationHash}) => {
    const [currentTab, setTab] = React.useState('');
    const [authState] = React.useContext(AuthenticationContext)
    React.useEffect(() => {
        if (locationHash !== '') {
            setTab(locationHash.split('#')[1])
        }
    }, [locationHash])


    const showTabs = () => {
        const global_customer_id = authState.customerInfo.global_customer_id || ''
        if (['C46A75', 'CDEV1A65'].indexOf(global_customer_id) !== -1) {
            return true;
        }
        return false
    }

    const tabOptions = showTabs() ? [
        {value: '', label: 'Overview',},
        {value: 'search', label: 'Search',},
        {value: 'recon-blocked', label: 'Recon Blocked'},
        {value: 'exports', label: 'Exports'},
    ] : [
        {value: '', label: 'Overview',},
        {value: 'recon-blocked', label: 'Recon Blocked'},
    ]


    const handleChangeTab = (value) => {
        if (!showTabs()) {
            if (value === 'search' || value === 'exports') {
                return
            }
        }
        let newTab = value || ''
        window.location.hash = newTab
        setTab(newTab)
    }


    return (
        <>
            <PageHeader pageTitle="Trackers" subText="Tracker information"/>
            <Box p={2} style={{padding: '0px 0px 30px 0px'}}>
                <Tabs style={{fontSize: '16px'}} value={currentTab} onChange={handleChangeTab} options={tabOptions}/>
            </Box>
            <SearchContextProvider>
                {currentTab === '' && <OverviewTab goToSearch={() => handleChangeTab('search')}/>}
                {currentTab === 'search' && <SearchTab goToExports={() => handleChangeTab('exports')}/>}
                {currentTab === 'recon-blocked' && <ReconBlockedTab/>}
                {currentTab === 'exports' && <ExportsTab/>}
            </SearchContextProvider>
        </>
    )
}

export default Trackers
