import React from 'react'
import Trackers from '../Trackers/Trackers'
import ProtectedViewLayout from '../App/ProtectedViewLayout';


const TrackersPage = ({ location }) => {
    return (
        <ProtectedViewLayout location={location}>
            <Trackers locationHash={location.hash} />
        </ProtectedViewLayout>
    );
}

export default TrackersPage